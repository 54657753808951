import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { IAppState, IPageTransition } from 'vev';
import {
  Tracking,
  useGlobalStateRef,
  useGlobalStore,
  useLiveEvent,
  useTracking,
  View as ViewManager,
} from '../core';
import { useAppInteractions } from '../core/hooks/use-app-interactions';
import Timeline from '../model/timeline';
import { openQueryParamsLink } from '../utils/link';
import { cleanPath, pageKeyByPath, pagePathByKey, updatePageHeader } from '../utils/route';
import Fixed from './fixed';
import Page from './page';
import View from './view';
import { useAppVariables } from '../core/hooks/use-app-variables';
import { StandardEventType } from '@vev/interactions';

const CENTER_VALUES = { x: 0, y: 0, opacity: 1, scale: 1 };

function App() {
  const [state, dispatch] = useGlobalStateRef();
  const [route, viewport] = useGlobalStore((state) => [state.route, state.viewport], []);
  const container = useRef<HTMLDivElement>(null);
  const currentPage = useRef<HTMLDivElement>(null);
  const nextPage = useRef<HTMLDivElement>(null);
  const disableRouter = useRef<boolean>();
  const hashBang = useRef<string>('');
  const pendingScrollToTop = useRef<boolean>(false);

  const { pageKey, widgetKey, transition } = route || {};
  const nextPageKey = transition ? transition.toPageKey : pageKey;

  const variableManager = useAppVariables(container);
  useAppInteractions(container, variableManager);

  const track = useTracking();

  useLayoutEffect(() => {
    dispatch('set-root', container.current ?? null);
  }, [dispatch, state, track]);

  useEffect(() => {
    track(StandardEventType.projectLoad, {
      projectKey: state.current.project,
      breakpoint: state.current?.device,
    });
  }, []);

  useEffect(() => {
    const onRoute = (e: PopStateEvent) => {
      disableRouter.current = true;
      dispatch('set-route-path', location.pathname);

      updatePageHeader(location.pathname);
    };
    window.addEventListener('popstate', onRoute);
    return () => window.removeEventListener('popstate', onRoute);
  }, []);

  useLiveEvent(
    'a',
    'click',
    container,
    (e, target) => {
      const forwardQueryParams = target.getAttribute('data-forward-query-params');
      if (e.button && !forwardQueryParams) return;

      const path = target.getAttribute('href') || '';
      if (!path) return;

      // Hack to be able to add relative link to the front page
      if (path === './') return (window.location.href = '/');

      const { route, pages, dir, embed, root } = state.current;
      const [pagePath, toWidgetKeyWithReferrer] = path.split('#');
      const toPageKey = pageKeyByPath(pagePath, pages, dir);

      const isInternal = !!toPageKey && target.getAttribute('target') !== '_blank';
      const isEmail = /^mailto/i.test(path);
      const isPhone = /^tel/i.test(path);
      let eventCategory: string;
      // if Is internal and router enabled with not ctrol or meta key click then prevent default
      // Also prevent default if no location.host ( in editor) and internal link

      if (isInternal && (!location.host || !(e.ctrlKey || e.metaKey))) {
        e.preventDefault();

        let tween: IPageTransition | undefined;
        if (target.dataset.tween && target.dataset.tween !== '{}') {
          try {
            tween = JSON.parse(target.dataset.tween.replaceAll('&quot;', '"')) as IPageTransition;
            // If missing tweenIN and tweenOut then invalid tween
            if (!tween.tweenIn && !tween.tweenOut) tween = undefined;
          } catch (e) {
            console.error('Failed to parse tween', e);
          }
        } else {
          const encoded = target.getAttribute('tween');
          if (encoded) {
            try {
              const parsed: IPageTransition = JSON.parse(decodeURIComponent(encoded));
              if (parsed) {
                tween = parsed;
                if (!tween.tweenIn && !tween.tweenOut) tween = undefined;
              }
            } catch (e) {
              console.error('Failed to parse tween', e);
            }
          }
        }
        hashBang.current = toWidgetKeyWithReferrer ? `#${toWidgetKeyWithReferrer}` : '';

        const [toWidgetKey] = (toWidgetKeyWithReferrer || '').split('?');
        const currentPageKey = (route.transition && route.transition.toPageKey) || route.pageKey;

        if (path.startsWith('#') || currentPageKey === toPageKey) {
          // Set link actual href in case something hijacks the click after this
          (e.target as HTMLLinkElement).href = `${pagePathByKey(
            toPageKey,
            pages,
            dir,
          )}#${toWidgetKey}`;

          if (toWidgetKey) {
            ViewManager.scrollTo(toWidgetKey, undefined, undefined, container.current || undefined);
          }

          return;
        }

        const nextRoute: IAppState['route'] = tween
          ? { pageKey: currentPageKey, transition: { toPageKey, tween, toWidgetKey } }
          : { pageKey: toPageKey, widgetKey: toWidgetKey };
        dispatch('route', nextRoute);

        if (!tween && !embed) {
          pendingScrollToTop.current = true;
        } else if (embed) {
          const top = container.current?.getBoundingClientRect()?.top;

          if (top && top < 0) {
            const insideEl = embed && root ? root : undefined;
            ViewManager.scrollToWidget(toPageKey, insideEl);
          }
        }

        eventCategory = 'Internal';
      } else if (isEmail) {
        eventCategory = 'Email';
      } else if (isPhone) {
        eventCategory = 'Phone';
      } else {
        eventCategory = 'Outbound';
        track(StandardEventType.linkClick, {
          url: path,
        });

        if (forwardQueryParams) {
          e.preventDefault();
          const openTarget = target.getAttribute('target');
          const rel = target.getAttribute('rel');
          openQueryParamsLink(path, { target: openTarget, rel }, state?.current?.embed);
        }
      }

      Tracking.send('link', eventCategory + ' Link', 'click', path);
    },
    [],
  );

  useLayoutEffect(() => {
    const { pages, embed, dir } = state.current;
    if (!nextPageKey) return;

    const path = pagePathByKey(nextPageKey, pages, dir);

    if (
      !embed &&
      !disableRouter.current &&
      location.host &&
      path !== cleanPath(location.pathname)
    ) {
      history.pushState(
        { pageKey: nextPageKey, scrollTop: 0 },
        document.title,
        path + location.search + hashBang.current,
      );
      updatePageHeader(path);
    } else if (disableRouter.current) disableRouter.current = false;
  }, [nextPageKey]);

  useLayoutEffect(() => {
    if (pendingScrollToTop.current) {
      pendingScrollToTop.current = false;
      if (widgetKey) {
        const { embed, root } = state.current;
        const insideEl = embed && root ? root : undefined;
        ViewManager.scrollToWidget(widgetKey, insideEl);
        dispatch('route', { pageKey });
      } else {
        ViewManager.setScrollTop(0);
      }
    }
  }, [widgetKey, dispatch, pageKey]);

  useLayoutEffect(() => {
    if (transition) {
      const { scrollTop, embed } = state.current;
      const { toPageKey, toWidgetKey, tween } = transition;
      if (toWidgetKey) ViewManager.scrollTo(toWidgetKey);
      const { current } = currentPage;
      const next = nextPage.current;

      const timeline = new Timeline(() => {
        if (!state.current.embed) ViewManager.setScrollTop(0);
        dispatch('route', { pageKey: toPageKey });
      });

      nextPage.current?.removeAttribute('style');

      if (currentPage.current) {
        currentPage.current.removeAttribute('style');
        currentPage.current.scrollTop = scrollTop;
      }

      const { width, height } = viewport || {};
      timeline.scaling({ x: width, y: height });

      if (nextPage.current && tween.tweenIn) {
        timeline.toValues(nextPage.current, tween.tweenIn, CENTER_VALUES);
      }

      if (currentPage.current && tween.tweenOut) {
        timeline.fromValues(currentPage.current, tween.tweenOut, CENTER_VALUES);
      }

      timeline.play();

      if (!embed) dispatch('scrollTop', 0);

      return () => {
        if (current) {
          timeline.remove(current);
        }

        if (next) {
          timeline.remove(next);
        }

        timeline.reset();
        if (current) current.removeAttribute('style');
      };
    }
  }, [transition]);

  return (
    <View ref={container}>
      {pageKey && <Fixed pageKey={pageKey} />}
      <Page
        key={pageKey || ''}
        className={transition && (!transition.tween.inFront ? 'front pin' : 'pin')}
        id={pageKey || ''}
        active
        ref={currentPage}
      />
      {transition && (
        <Page key={transition.toPageKey} className="pin" id={transition.toPageKey} ref={nextPage} />
      )}
    </View>
  );
}

export default React.memo(App);
