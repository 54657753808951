import { initInteractionsNode } from '@vev/interactions';
import { VariableManager } from '@vev/variables';
import { useEffect } from 'react';
import View from '../../manager/view';
import { useGlobalStateRef } from './use-global-state';
import { useGlobalStore } from './use-store';
import { useSystemVariableSubscription } from './use-system-variable-subscription';
import { useContextModel } from '../context/model';

export function useAppInteractions(
  rootNodeRef: React.RefObject<HTMLDivElement>,
  variableManager: VariableManager,
  debug = false,
) {
  const systemVariableSub = useSystemVariableSubscription();
  const [state, dispatch] = useGlobalStateRef();
  const [pageKey, disabled] = useGlobalStore((s) => [s.route.pageKey, s.editor?.disabled], []);
  const model = useContextModel(pageKey);
  const hasPage = !!model;

  const projectKey = state.current.project;
  useEffect(() => {
    const rootNode = rootNodeRef.current;
    if (hasPage && rootNode && projectKey && !disabled) {
      const { interactions, animations, route } = state.current;
      return initInteractionsNode({
        interactions,
        page: route.pageKey,
        project: projectKey,
        animations,
        rootNode,
        requestVariable(name) {
          return variableManager.get(name);
        },
        debug,
        systemVariableSub,
      });
    }
  }, [
    pageKey,
    state,
    hasPage,
    projectKey,
    disabled,
    rootNodeRef,
    debug,
    variableManager,
    systemVariableSub,
  ]);

  useEffect(() => {
    window.addEventListener<any>('vev.navigate.internal', (e: CustomEvent) => {
      const { pageKey, widgetKey } = e.detail;
      if (pageKey) dispatch('route', { pageKey });
      if (widgetKey) View.scrollTo(widgetKey);
    });
  });
}
